<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<div class="banner">
				<img src="../../assets/images/life-milestone.jpg" alt="">
			</div>

			<div class="tab-pane active" id="dashij" style="padding-bottom:0px;background: #fff">
				<div class="main about-tab-pane">
					<div class="dsj-year" v-for="(item,index) of timeList" :key="index">
						<div class="dsj-year" v-if="index%2 == 0">
							<div class="left y-2016">
								<div class="text-year en3 y-2016">{{item.year}}</div>
							</div>
							<div class="right">
								<ul>
									<div v-for="(event,index) of item.eventList" :key="index">
										<li class="title">
											{{event.month}}
										</li>
										<li>
											{{event.title}}
										</li>
									</div>
								</ul>
							</div>
						</div>
						<div class="dsj-year" v-if="index%2 != 0">
							<div class="left y-2017">
								<div class="text-year en3 y-2017">{{item.year}}</div>
								<ul>
									<div v-for="(event,index) of item.eventList" :key="index">
										<li class="title">
											{{event.month}}
										</li>
										<li>
											{{event.title}}
										</li>
									</div>
								</ul>
							</div>
							<div class="right">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'

	export default {
		data() {
			return {

				timeList: [{
						year: "2020",
						eventList: [{
								month: "9月20",
								title: "小院生活·Y Cafe 门店开业",
							},
							{
								month: "2月10",
								title: "小院生活向渭南经开管委会捐赠十万元抗疫物资",
							},
							{
								month: "1月23",
								title: "小院生活·长安十三茶芙蓉园龙舫店开业",
							},
						],
					},

					{
						year: "2019",
						eventList: [{
								month: "10月15",
								title: "渭南小院·经开未来城项目奠基开工",
							},
							{
								month: "9月20",
								title: "小院生活馆MOMOPARK华夫茶饼店开业",
							},
							{
								month: "8月30",
								title: "小院生活馆渭南万达广场店开业",
							},
							{
								month: "7月20",
								title: "小院生活馆渭南宏帆广场店开业",
							},
						],

					},



					{
						year: "2018",
						eventList: [{
								month: "12月25",
								title: "小院生活 “水一方·小院”项目完全竣工",
							},
							{
								month: "11月17",
								title: "公司总裁袁国谦参加第五届中国企业全球化论坛",
							},
							{
								month: "3月6",
								title: "公司总裁袁国谦参加首届中英人居环境峰会论坛",
							},

						],

					},


					{
						year: "2017",
						eventList: [{
								month: "12月1",
								title: "小院生活 “水半湾·小院”项目完全竣工",
							},
							{
								month: "10月20",
								title: "小院生活“奥韵华府·小院”项目正式开工",
							},
							{
								month: "8月20",
								title: "公司总裁袁国谦应邀参加首届世界西商大会",
							},


						],

					},

					{
						year: "2016",
						eventList: [{
								month: "4月",
								title: "伦敦大学建筑学院副院长DAVID到小院生活参观交流",
							},
							{
								month: "4月",
								title: "交大创新港公司到小院生活参观考察",
							},
							{
								month: "1月",
								title: "英国皇家建筑设计研究院曹春丽博士一行到访小院生活",
							},
						],

					},

					{
						year: "2015",
						eventList: [{
								month: "8月20",
								title: "西安小院科技股份有限公司正式注册成立",
							},
							{
								month: "3月1",
								title: "小院生活筹备组首次召开股东大会",
							},
						],

					},


				]

			}
		},
		components: {
			Header2022,
			Footer2022,
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		padding-top: 64px;
	}

	.banner {
		width: 100%;

		img {
			width: 100%;
		}
	}

	#dashij {
		width: 100%;
		font-family: blanch-sed;
		color: #303233;
	}

	.main {
		margin: 0 auto;
		width: 1200px;
		min-width: 1200px;
		height: auto;
		clear: both;
		overflow: hidden;
		max-width: 1200px;
	}

	.about-tab-pane {
		box-sizing: border-box;
		padding: 80px 24px 80px 24px;
	}

	.dsj-year {
		display: table;
		padding-top: 20px;
		width: 100%;
	}

	.dsj-year>.left,
	.dsj-year>.right {
		display: table-cell;
		width: 50%;
		box-sizing: content-box;
		padding-left: 80px;
	}

	.dsj-year>.left>.image,
	.dsj-year>.right>.image {
		position: relative;
	}

	.dsj-year>.left>.image>.word,
	.dsj-year>.right>.image>.word {
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 8px;
		line-height: 24px;
		color: #fff;
		left: 0;
		right: 0;
	}

	.dsj-year>.left::before {
		background-color: #00cb87;
		content: ' ';
		position: absolute;
		width: 21px;
		height: 21px;
		border-radius: 21px;
		right: -11px;
		top: 0;
	}

	.dsj-year>.left::after {
		background-color: #ffffff;
		content: ' ';
		position: absolute;
		width: 15px;
		height: 15px;
		border-radius: 15px;
		right: -8px;
		top: 3px;
	}

	.dsj-year>.left .text-year.en3 {
		font-size: 26px;
		width: 65px;
		position: absolute;
		right: -34px;
		word-wrap: break-word;
		display: block;
		padding: 10px 0 10px 0;
		background-color: #fff;
		color: #dcdcdc;
		top: -60px;
	}

	.dsj-year>.left>ul li,
	.dsj-year>.right>ul li {
		color: #303233;
		text-align: right;
		margin-bottom: 45px;
		font-size: 14px;
		line-height: 24px;
	}

	.dsj-year>.left>ul li.title,
	.dsj-year>.right>ul li.title {
		color: #dcdcdc;
		margin-bottom: 0;
		font-size: 24px;
	}

	.dsj-year>.left {
		padding-left: 0;
		padding-right: 80px;
		vertical-align: top;
		position: relative;
		border-right: 1px solid #dcdcdc;
	}

	.dsj-year>.right>ul li {
		text-align: left;
	}
</style>
